import React from "react"
import { Carousel } from "react-bootstrap"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CatLayoutImage from "../components/CatLayoutImage"
import CatCabinImage from "../components/CatCabinImage"
import CatKitchenImage from "../components/CatKitchenImage"
import CatOpenSpaceImage from "../components/CatOpenSpaceImage"
import CatSalonImage from "../components/CatSalonImage"

const CharterPage = () => (
  <Layout pageInfo={{ pageName: "charter" }}>
    <SEO title="Charter" />
    <h1 className="heading-4">Kojencharter</h1>

    <p className="content-text">
      <br/>
Du segelst mit einem erfahrenen Skipper und kannst Dich aktiv beim Segeln beteiligen,
Segelerfahrung und Seemeilen sammeln – musst das aber nicht.
Ein Segelschein ist keine Voraussetzung.
Alle sonstigen täglichen Aufgaben des Lebens an Bord (Einkaufen, Kochen) werden gemeinsam
von der Crew erledigt. Stell dir das ganze wie Camping auf dem Wasser vor.
Wenn sich bei Dir jetzt schon alle Haare aufstellen und du es liebst im Urlaub,
keinen Finger zu rühren, schau lieber nochmal beim Pauschalanbieter nach All-Inclusive Angeboten.
Aber keine Sorge, es bleibt auch beim Abenteuerurlaub genug Zeit zum Entspannen.
Mit dem Dinghy können wir selbst von Ankerbuchten aus an Land gehen und in gemütlichen Tavernen Abend essen.
    </p>

    <p className="content-text">
    Unser Katamaran Jolie vom Typ Bali 4.1 ist Baujahr 2019 und segelt ab Mai 2022 von Preveza (ca. 100km südlich von Korfu) um den Peleponnes in die Kykladen mit Zustieg
    in Paros (Juni-August) und kehrt bis Anfang Oktober nach Preveza zurück.
    Bitte fragt per Anfrage-Forumular die genauen Zustiegsmöglichkeiten für 2022 an, Flughäfen entlang der Route sind z.B. in Preveza (PVK), Zakynthos (ZTH), Kalamata (KLX), Kythira (KIT) oder Paros (PAS).
    Im Juni und Juli sind primär Kitecruises (Kitesurfen & Wingfoilen vom Katamaran) in den Kykladen geplant.
    <br/>
    </p>

    <Carousel className="border">
      <Carousel.Item>
        <CatLayoutImage />
        <Carousel.Caption>
        <h3>Layout</h3>
        <p>Je Rumpf zwei geräumige Doppelkabinen und ein Bad mit Dusche und Toilette.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CatOpenSpaceImage />
        <Carousel.Caption>
        <h3>Open-Space</h3>
        <p>Flexible Raumaufteilung offen oder geschlossen je nach Witterungsbedingungen.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CatSalonImage />
        <Carousel.Caption>
        <h3>Salon</h3>
        <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CatKitchenImage />
        <Carousel.Caption>
        <h3>Küche</h3>
        <p>Geräumige Küche inklusive Geschirrspüler</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <CatCabinImage />
        <Carousel.Caption>
        <h3>Koje</h3>
        <p>Achterkoje mit ca. 160cm breitem Doppelbett.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

  <p className="content-text">
  <br/>
Die grobe Route wird gemeinsam vor Törnbeginn mit deinen Mitseglern festgelegt,
und täglich je nach Wind und Wetterbedingungen abgestimmt.
Während dieser Woche ist ein bunter Mix aus Segeln, Baden, Schnorcheln, SUPen,
Wakeboarden, Kitesurfen, Landgängen und Entspannung möglich.
Letztendlich entscheidet (neben dem Wind) die Crew wo der Fokus liegen soll.
</p>
  <p className="content-text">
Ich hoffe dass wir Dich zum Träumen vom nächsten Urlaub auf See bringen konnten, bitte teile uns ganz unverbindlich mit wie Dein Traumtörn aussehen sollte: &nbsp;&nbsp;
<Link to="/booking-request" className="action-button">unverbindliche Anfrage</Link><br/>
  </p>
  </Layout>
)

export default CharterPage
